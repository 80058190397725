:root {
  --rsbs-backdrop-bg: rgba(0, 0, 0, 0.75);
  --rsbs-bg: hsl(240, 4%, 15%);
  --rsbs-handle-bg: hsla(0, 0%, 100%, 0.18);
  --rsbs-max-w: auto;
  --rsbs-ml: env(safe-area-inset-left);
  --rsbs-mr: env(safe-area-inset-right);
  --rsbs-overlay-rounded: 16px;
}

#layout {
  min-height: calc(100vh - 62px);
  padding-top: 62px;
}
